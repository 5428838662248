import React, { useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout/layout";
import BreadCrumb from "../components/layout/breadcrumb";
import ColtelloBlok from "../components/storyblok-components/products/coltello-blok";
import { LanguageContext } from "../context/language-context";
import t from "../resources/translations";
import { slugs2name } from "../resources/categorie";
import LamaBlok from "../components/storyblok-components/products/lama-blok";
import { cat2bgImg } from "../resources/images";
import PunteBlok from "../components/storyblok-components/products/punte-blok";
import FreseBlok from "../components/storyblok-components/products/frese-blok";
import AccessoriBlok from "../components/storyblok-components/products/accessori-blok";
import RichtextBlok from "../components/storyblok-components/richtext-blok";
import Seo from "../components/layout/seo";

const page2component = {
  coltello: ColtelloBlok,
  lama: LamaBlok,
  punte: PunteBlok,
  frese: FreseBlok,
  accessori: AccessoriBlok,
};

const GalleryProduct = ({ imgs, isDefImg }) => {
  const [activeImg, setActiveImg] = useState(0);

  return (
    <div className="md:flex-1 mb-6 md:mb-0">
      <div className="md:shadow-is md:rounded-is md:overflow-hidden bg-[#656565] p-4 min-h-[500px]">
        {isDefImg ? (
          imgs[activeImg]
        ) : (
          <div className="flex justify-center">
            <img
              src={imgs[activeImg].filename}
              alt={imgs[activeImg].alt}
              className="max-h-[500px]"
            />
          </div>
        )}
      </div>
      {!isDefImg && imgs.length > 1 && (
        <div className="grid grid-cols-3 gap-7 mt-3 mb-6 md:mt-5">
          {imgs.map((img, idx) => (
            <div
              key={img.filename + img.alt}
              onClick={() => {
                setActiveImg(idx);
              }}
              className={
                "border-4 bg-[#656565] cursor-pointer " +
                (idx === activeImg ? "  border-is-blue" : "border-gray-200")
              }
            >
              <img src={img.filename} alt={img.alt} className="w-full" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const tabs = ["descrizione"];

const Specifiche = ({ content }) => {
  const [activeTab, setActiveTab] = useState(0);
  const l = useContext(LanguageContext).locale;

  const info = content?.info;
  const e1 =
    typeof info === "object" && info.length > 0 ? info[0]?.descrizione : "";
  const e2 =
    typeof info === "object" && info.length > 0 ? info[0]?.specifiche : "";

  if (!(e1 || e2)) {
    return null;
  }

  return (
    <div className="py-4 md:w-10/12 md:mx-auto">
      <div className="flex mb-11 md:justify-center md:mb-0">
        {tabs.map((tab, idx) => (
          <p
            key={tab}
            onClick={() => {
              if (activeTab !== idx) setActiveTab(idx);
            }}
            className={
              "flex-auto md:flex-none md:px-8 text-center py-3 border-is-blue cursor-pointer text-white " +
              (activeTab === idx ? "border-b-2 is-pb" : "is-p")
            }
          >
            {t[tab][l]}
          </p>
        ))}
      </div>
      <div className="overflow-hidden relative p-1">
        <div
          style={{
            width: tabs.length + "00%",
            left: `calc(4px - ${activeTab}00% - ${activeTab * 8}px)`,
          }}
          className="relative transition-all flex gap-4"
        >
          <div className="shadow-is py-4 px-7 rounded-is bg-is-dark-gray text-white flex-1 md:py-11 md:px-28">
            {typeof e1 === "object" ? <RichtextBlok data={e1} /> : e1}
            {typeof e2 === "object" ? <RichtextBlok data={e2} /> : e2}
          </div>
        </div>
      </div>
    </div>
  );
};

const Product = ({ data, location }) => {
  let story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const l = useContext(LanguageContext).locale;

  const cat = story.field_component.replace("pagina-", "");
  const ComponentBlok = page2component[cat];
  const imgsNum = story?.content?.imgs?.length ?? 0;
  const [imgs, isDefImg] =
    imgsNum > 0 ? [story?.content?.imgs, false] : [[cat2bgImg[cat]], true];

  const tit =
    (story?.content?.titolo ?? story.name) +
    " - " +
    story?.content?.sottotitolo;

  return (
    <Layout location={location}>
      <Seo
        title={tit}
        desc={`${tit}. ${t.scopriProd[l]} ${slugs2name[cat][l]}. ${t.contPerInfo[l]}`}
      />
      <BreadCrumb>
        <Link to="../..">{t.home[l]}</Link>
        <Link to="..">{slugs2name[cat][l]}</Link>
        <p className="font-bold">{story?.content?.titolo ?? story.name}</p>
      </BreadCrumb>
      <div className="md:flex md:gap-12 md:items-start mb-2">
        <h1 className="is-h1m md:hidden text-white">
          {story?.content?.titolo ?? story?.name}
        </h1>
        {story?.content?.sottotitolo && (
          <h2 className="text-white text-lg md:hidden mb-2">
            {story.content.sottotitolo}
          </h2>
        )}
        <GalleryProduct imgs={imgs} isDefImg={isDefImg} />
        {ComponentBlok && (
          <ComponentBlok
            content={story.content}
            name={story.name}
            cat={cat}
            slug={story.slug}
            wishlistImg={isDefImg ? null : imgs[0]}
          />
        )}
      </div>
      <Specifiche content={story.content} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
  }
`;

export default Product;
