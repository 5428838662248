import React, { useContext } from "react";
import { addToWishlist, InteractionBlok } from "./utils";
import { WishlistContext } from "../../../context/wishlist-context";

const AccessoriBlok = ({ content, name, cat, slug, wishlistImg }) => {
  const wishCtx = useContext(WishlistContext);

  const wishlistObject = {
    k: name,
    o: {
      name,
      cat,
      slug,
      wishlistImg,
      titolo: content?.titolo,
      sottotitolo: content?.sottotitolo,
    },
  };

  const alreadyAdded =
    wishlistObject !== null && wishlistObject.k in wishCtx.wishlist;

  return (
    <div className="shadow-is py-4 px-7 md:py-10 md:px-16 rounded-is bg-is-dark-gray md:flex-1">
      <h1 className="is-h1 text-white hidden md:block">
        {content?.titolo ?? name}
      </h1>
      {content?.sottotitolo && (
        <h2 className="text-white text-lg hidden md:block mb-8">
          {content.sottotitolo}
        </h2>
      )}
      <InteractionBlok
        selectedIdx={1}
        alreadyAdded={alreadyAdded}
        aggiungiOnClick={() => {
          addToWishlist(wishlistObject, alreadyAdded, wishCtx);
        }}
      />
    </div>
  );
};

export default AccessoriBlok;
