import React, { useEffect, useMemo, useState } from "react";
import arrowProductCard from "../../../resources/images/arr-product-card.svg";

const MIN = -1000000,
  MAX = 1000000,
  N = 3;

const FromTo = ({ values }) => {
  const [[min, max], setMinMax] = useState([MAX, MIN]);
  const valueSet = useMemo(() => [...new Set(values)], [values]);

  useEffect(() => {
    const minmax = values.reduce(
      (cur, nex) => [Math.min(cur[0], nex), Math.max(cur[1], nex)],
      [MAX, MIN]
    );
    setMinMax(minmax);
  }, [values]);

  return valueSet.length > N && !isNaN(min) && !isNaN(max) ? (
    <>
      <div className="p-1.5 border border-white text-white">{min}</div>
      <img src={arrowProductCard} alt="arrow right" className="px-2" />
      <div className="p-1.5 border border-white text-white">{max}</div>
    </>
  ) : (
    <>
      {valueSet.map((v) => (
        <div key={v} className="p-1.5 border border-white text-white">
          {v}
        </div>
      ))}
    </>
  );
};

// if available retrieve the materials from the content, otherwise empty array
const getMaterials = (content) =>
  (content?.materiali ?? [{}])[0]?.materiale ?? [];

export { FromTo, getMaterials };
