import React from "react";

const RichtextBlok = ({
  data,
  pClassName,
  ulClassName,
  liClassName,
  className,
}) => (
  <div className={className}>
    {(data?.content ?? []).map((e) =>
      getComponent(e, { pClassName, ulClassName, liClassName })
    )}
  </div>
);

const getComponent = (e, c, idx) => {
  switch (e.type) {
    case "text":
      return <SpanBlok data={e} key={idx} classes={c} />;
    case "hard_break":
      return <br key={idx} classes={c} />;
    case "paragraph":
      return <PBlok data={e.content} key={idx} classes={c} />;
    case "ordered_list":
      return <OLBlok data={e.content} key={idx} classes={c} />;
    case "bullet_list":
      return <ULBlok data={e.content} key={idx} classes={c} />;

    default:
      return null;
  }
};

const mark2class = {
  bold: "font-bold",
};

const PBlok = ({ data, classes }) => {
  return (
    <p className={classes?.pClassName ?? ""}>
      {data ? (
        data.map((c, idx) => getComponent(c, classes, idx))
      ) : (
        <span>&nbsp;</span>
      )}
    </p>
  );
};

const SpanBlok = ({ data, classes }) => {
  const styles = (data?.marks ?? []).map((m) => mark2class[m.type]).join(" ");
  return <span className={styles}>{data.text}</span>;
};

const OLBlok = ({ data, classes }) => (
  <ol className="list-decimal ml-5">
    {data.map((e, idx) => (
      <li key={idx} className={classes.liClassName}>
        {getComponent(e.content[0], classes)}
      </li>
    ))}
  </ol>
);

const ULBlok = ({ data, classes }) => (
  <ul className={classes?.ulClassName ?? ""}>
    {data.map((e, idx) => (
      <li key={idx} className={classes.liClassName}>
        {getComponent(e.content[0], classes)}
      </li>
    ))}
  </ul>
);

RichtextBlok.defaultProps = {
  data: { content: [] },
  className: "prose-lg max-w-prose mx-auto",
  pClassName: "mt-2",
  ulClassName: "ml-2",
  liClassName: "mt-2 ml-1",
};

export default RichtextBlok;
