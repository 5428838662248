import { Listbox } from "@headlessui/react";
import React, { useContext } from "react";
import { LanguageContext } from "../../../context/language-context";
import arrProd from "../../../resources/images/arr-product.svg";
import prefActive from "../../../resources/images/pref-active.svg";
import prefDisabled from "../../../resources/images/pref-disabled.svg";
import prefSelected from "../../../resources/images/pref-selected.svg";
import t from "../../../resources/translations";
import resetta from "../../../resources/images/reset.svg";
import { materials } from "../../../resources/categorie";
import { getMaterials } from "../cards/utils";
import { WishlistContext } from "../../../context/wishlist-context";
/* global dataLayer */

const AttrSelector = ({ value, setter, availabilities, def, icon, title }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <div>
      <h3 className="is-h3b mb-2 md:mb-2 text-is-blue">{title}</h3>
      <p className="mb-7 md:mb-7 is-p text-lg">{t.disponibiliSetup[l]}</p>
      <div className="flex gap-5 mb-8 md:mb-11 items-center">
        <img src={icon} alt={def} className="w-12" />
        <Listbox value={value} onChange={setter} as="div" className="relative">
          <Listbox.Button className="bg-white text-is-light-gray px-3 py-2.5 rounded-small is-comm-flex gap-3">
            {value ?? def}
            <img src={arrProd} alt="" />
          </Listbox.Button>
          <Listbox.Options className="bg-white text-is-light-gray py-2.5 rounded-small mt-2 absolute w-full z-10 flex flex-col">
            {availabilities.map(([v, isAvail], idx) => (
              <Listbox.Option
                key={v}
                value={v}
                disabled={!isAvail}
                className={
                  " py-2  border-white text-center cursor-pointer hover:bg-gray-300 " +
                  (isAvail ? "" : "bg-gray-300 opacity-50 cursor-not-allowed")
                }
              >
                {v}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
};

const InteractionBlok = ({
  selectedIdx,
  alreadyAdded,
  aggiungiOnClick,
  inviaOnClick,
}) => {
  const l = useContext(LanguageContext).locale;
  const { setIsOpen, setInviaRichiesta } = useContext(WishlistContext);
  return (
    <div className="is-comm-flex-md">
      <button
        className="flex gap-1.5 items-center mb-4 text-white disabled:text-is-disabled-gray disabled:cursor-not-allowed"
        disabled={selectedIdx === null}
        onClick={() => {
          aggiungiOnClick();
          setIsOpen(true);
        }}
      >
        <img
          src={
            selectedIdx === null
              ? prefDisabled
              : alreadyAdded
              ? prefSelected
              : prefActive
          }
          alt=""
        />
        <span className="is-h4">
          {alreadyAdded ? t.aggiuntoWishlist[l] : t.aggiungiWishlist[l]}
        </span>
      </button>
      <button
        className="is-button disabled:bg-is-light-gray disabled:cursor-not-allowed transition-colors"
        disabled={selectedIdx === null}
        onClick={() => {
          setInviaRichiesta(true);
        }}
      >
        {t.inviaRichiesta[l]}
      </button>
    </div>
  );
};

const ResetFilters = ({ resetState }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <button
      className="cursor-pointer flex gap-4 ml-4 mt-4 mb-5 items-center"
      onClick={resetState}
    >
      <img src={resetta} alt="" />
      <p className="text-white underline">{t.resettaFiltri[l]}</p>
    </button>
  );
};

const MaterialiBlok = ({ content }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <>
      <h3 className="is-h3b mb-2 md:mb-2 text-is-blue">{t.matLavorare[l]}</h3>
      <div className="mb-8 md:mb-11 is-p text-white uppercase text-lg ">
        {getMaterials(content)
          .map((m) => (m in materials ? materials[m][l] : m))
          .join(", ")}
      </div>
    </>
  );
};

const addToWishlist = (wishlistObject, alreadyAdded, wishCtx) => {
  if (wishlistObject !== null && !alreadyAdded) {
    // TODO: use codice prodotto instead
    dataLayer &&
      dataLayer.push({
        event: "add_to_wishlist",
        prodotto: wishlistObject?.o?.titolo ?? wishlistObject?.o?.name,
      });
    wishCtx.addToWishlist(wishlistObject.k, wishlistObject.o);
  }
};

export {
  AttrSelector,
  InteractionBlok,
  ResetFilters,
  MaterialiBlok,
  addToWishlist,
};
