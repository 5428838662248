import React, { useContext, useMemo, useState } from "react";
import {
  addToWishlist,
  AttrSelector,
  InteractionBlok,
  MaterialiBlok,
  ResetFilters,
} from "./utils";
import { WishlistContext } from "../../../context/wishlist-context";
import t from "../../../resources/translations";
import { LanguageContext } from "../../../context/language-context";
import { attr2icon } from "../../../resources/component-mapping";

const LamaBlok = ({ content, name, cat, slug, wishlistImg }) => {
  const varianti = content.varianti;
  console.log(content);

  const l = useContext(LanguageContext).locale;

  const [state, setState] = useState({
    d_lama: null,
    n_denti: null,
    d_foro: null,
    spessore: null,
  });

  const wishCtx = useContext(WishlistContext);

  const genAvail = (attr) => () =>
    Object.entries(
      varianti.reduce((obj, variante) => {
        const v = variante[attr];
        const isAvail = Object.entries(state).every(
          ([a, v]) => a === attr || v === null || variante[a] == v
        );
        if (isAvail || !(v in obj)) {
          obj[v] = isAvail;
        }
        return obj;
      }, {})
    ).sort((a, b) => a[0] - b[0]);

  const avDLama = useMemo(genAvail("d_lama"), [state, varianti]);

  const avNDenti = useMemo(genAvail("n_denti"), [state, varianti]);

  const avDForo = useMemo(genAvail("d_foro"), [state, varianti]);

  const avSpessori = useMemo(genAvail("spessore"), [state, varianti]);

  const genSetter = (attr) => (value) => {
    setState((old) => ({
      ...old,
      [attr]: value,
    }));
  };

  const selectedIdx = useMemo(() => {
    const selected = Object.values(state).every((v) => v !== null);
    const r = varianti
      .map((variante, i) => [
        Object.entries(state).every(([a, v]) => v === null || variante[a] == v),
        i,
      ])
      .filter((v) => v[0]);
    return selected && r.length >= 1 ? r[0][1] : null;
  }, [varianti, state]);

  const resetState = () => {
    setState((old) =>
      Object.keys(old).reduce((o, k) => {
        o[k] = null;
        return o;
      }, {})
    );
  };

  const wishlistObject = useMemo(() => {
    if (selectedIdx === null) return null;
    const k = name + Object.values(state).join("-");
    const o = {
      name,
      cat,
      slug,
      wishlistImg,
      titolo: content?.titolo,
      sottotitolo: content?.sottotitolo,
      ...state,
    };
    return { k, o };
  }, [selectedIdx, state]);

  console.log("selected id is " + selectedIdx);

  const alreadyAdded =
    wishlistObject !== null && wishlistObject.k in wishCtx.wishlist;

  return (
    <div className="shadow-is py-4 px-7 md:py-10 md:px-16 rounded-is bg-is-dark-gray md:flex-1">
      <h1 className="is-h1 text-white hidden md:block">
        {content?.titolo ?? name}
      </h1>
      {content?.sottotitolo && (
        <h2 className="text-white text-lg hidden md:block mb-8">
          {content.sottotitolo}
        </h2>
      )}
      <ResetFilters resetState={resetState} />
      <div className="grid md:grid-cols-2">
        <AttrSelector
          value={state.d_lama}
          setter={genSetter("d_lama")}
          availabilities={avDLama}
          def={t.default[l]}
          icon={attr2icon["d_lama"]}
          title={t.d_lama[l]}
        />
        <AttrSelector
          value={state.n_denti}
          setter={genSetter("n_denti")}
          availabilities={avNDenti}
          def={t.default[l]}
          icon={attr2icon["n_denti"]}
          title={t.n_denti[l]}
        />
        <AttrSelector
          value={state.d_foro}
          setter={genSetter("d_foro")}
          availabilities={avDForo}
          def={t.default[l]}
          icon={attr2icon["d_foro"]}
          title={t.d_foro[l]}
        />
        <AttrSelector
          value={state.spessore}
          setter={genSetter("spessore")}
          availabilities={avSpessori}
          def={t.default[l]}
          icon={attr2icon["spessore"]}
          title={t.spessore[l]}
        />
      </div>
      <MaterialiBlok content={content} />
      <InteractionBlok
        selectedIdx={selectedIdx}
        alreadyAdded={alreadyAdded}
        aggiungiOnClick={() => {
          addToWishlist(wishlistObject, alreadyAdded, wishCtx);
        }}
      />
    </div>
  );
};

export default LamaBlok;
